/** @jsx jsx */
import { jsx } from '@emotion/react';

export function OverdriveIconSVG({ color, ...props }) {
  return (
    <svg
      width="121"
      height="24"
      viewBox="0 0 121 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M16.5312 3.70441C21.8885 3.70441 25.6055 7.31524 24.573 12.0176C23.57 16.5666 18.3248 20.2424 12.9026 20.2424C7.48037 20.2424 3.82234 16.5666 4.82535 12.0176C5.85787 7.29756 11.168 3.70441 16.5312 3.70441ZM13.4926 17.493C17.0916 17.493 20.437 14.9382 21.0802 11.994C21.7528 8.94365 19.4694 6.45382 15.9234 6.45382C12.3775 6.45382 8.99669 8.94365 8.32409 11.994C7.67506 14.9441 9.89352 17.493 13.4926 17.493Z" />
      <path d="M30.7858 15.8999L35.7419 7.99963H39.4649L31.0985 19.9179H28.4435L25.3518 7.99963H29.0276L30.7091 15.8999H30.7858Z" />
      <path d="M40.2968 15.245C40.2968 16.0179 41.2349 17.6936 43.6599 17.6936C44.3353 17.72 45.0083 17.5967 45.6305 17.3325C46.2527 17.0683 46.8088 16.6697 47.2589 16.1654H50.8344C49.5423 18.3131 46.4271 20.2956 43.0817 20.2956C38.7687 20.2956 36.2317 17.4576 36.9987 13.9824C37.7362 10.6076 41.3648 7.58081 45.8489 7.58081C50.4923 7.58081 52.4687 10.7787 51.8079 13.7877C51.6932 14.2923 51.5189 14.7815 51.2888 15.245H40.2968ZM48.557 12.944C48.5924 11.0324 47.141 10.2181 45.3296 10.2181C44.3825 10.1908 43.447 10.4323 42.6315 10.9147C41.8159 11.3972 41.1537 12.1007 40.7216 12.944H48.557Z" />
      <path d="M54.2034 7.99972H57.3186L57.0826 9.05584H57.1357C57.5469 8.57581 58.0601 8.19374 58.6378 7.93752C59.2156 7.6813 59.8433 7.55742 60.4751 7.57493H60.7583L60.1683 10.348C57.0589 10.4541 56.6282 12.4897 56.4512 13.2036L54.9763 19.9003H51.5601L54.2034 7.99972Z" />
      <path d="M62.4636 4.03485H66.753C71.3964 4.03485 73.1782 5.08509 74.0986 5.88161C74.9136 6.65522 75.5072 7.63229 75.8184 8.712C76.1295 9.79172 76.1469 10.9349 75.8686 12.0236C75.2373 14.8792 72.8714 17.6287 69.8387 18.8913C67.9271 19.7114 65.455 19.9238 63.3486 19.9238H58.9825L62.4636 4.03485ZM63.0536 17.1744H63.797C65.4217 17.2737 67.0484 17.018 68.5643 16.4251C69.4925 16.039 70.3184 15.4426 70.9768 14.6829C71.6351 13.9232 72.108 13.0209 72.3581 12.0472C72.5318 11.3493 72.5138 10.6175 72.3061 9.92899C72.0984 9.24049 71.7087 8.6208 71.1781 8.13539C69.998 7.02027 68.1808 6.80788 65.986 6.80788H65.3192L63.0536 17.1744Z" />
      <path d="M77.6563 7.99966H80.7539L80.5238 9.05577H80.571C80.986 8.571 81.5049 8.1861 82.0892 7.9297C82.6735 7.67329 83.3082 7.552 83.9459 7.57487H84.2291L83.6391 10.3479C80.5239 10.454 80.099 12.4896 79.922 13.2035L78.447 19.9002H75.0544L77.6563 7.99966Z" />
      <path d="M85.0551 7.99969H88.4241L85.8044 19.918H82.4119L85.0551 7.99969ZM85.9342 4.03485H89.3268L88.7368 6.65449H85.356L85.9342 4.03485Z" />
      <path d="M94.5662 15.8999L99.5223 7.99963H103.251L94.8848 19.9179H92.2297L89.1381 7.99963H92.8138L94.4895 15.8999H94.5662Z" />
      <path d="M104.354 15.245C104.354 16.0179 105.293 17.6936 107.717 17.6936C108.393 17.72 109.066 17.5967 109.688 17.3325C110.31 17.0683 110.866 16.6697 111.317 16.1654H114.892C113.6 18.3131 110.479 20.2956 107.139 20.2956C102.826 20.2956 100.289 17.4576 101.056 13.9824C101.8 10.6076 105.422 7.58081 109.906 7.58081C114.55 7.58081 116.526 10.7787 115.865 13.7877C115.745 14.2931 115.565 14.7823 115.329 15.245H104.354ZM112.615 12.944C112.65 11.0324 111.199 10.2181 109.387 10.2181C108.44 10.1908 107.505 10.4323 106.689 10.9147C105.874 11.3972 105.211 12.1007 104.779 12.944H112.615Z" />
      <path d="M117.972 7.71053C117.897 8.05366 117.711 8.36226 117.442 8.58767C117.172 8.81307 116.836 8.94245 116.485 8.95543C116.328 8.96351 116.172 8.9349 116.028 8.87179C115.884 8.80868 115.757 8.71285 115.657 8.59195C115.556 8.47106 115.486 8.32842 115.45 8.1754C115.415 8.02238 115.416 7.86319 115.453 7.71053C115.528 7.36597 115.717 7.05653 115.988 6.83108C116.26 6.60563 116.599 6.47705 116.951 6.46564C117.107 6.45945 117.262 6.4895 117.405 6.55343C117.547 6.61735 117.672 6.71343 117.771 6.83408C117.87 6.95472 117.94 7.09664 117.975 7.24872C118.009 7.40081 118.008 7.55888 117.972 7.71053ZM115.753 7.71053C115.723 7.82737 115.72 7.94973 115.745 8.06781C115.771 8.18589 115.823 8.29644 115.899 8.39052C115.975 8.48459 116.072 8.55956 116.181 8.60951C116.291 8.65947 116.412 8.68302 116.532 8.67818C116.804 8.66802 117.064 8.5672 117.271 8.39181C117.479 8.21642 117.622 7.97654 117.677 7.71053C117.705 7.59369 117.706 7.47185 117.679 7.35461C117.653 7.23738 117.599 7.12791 117.523 7.03476C117.447 6.94161 117.351 6.86735 117.241 6.81774C117.132 6.76813 117.012 6.74452 116.892 6.74881C116.621 6.75363 116.359 6.85195 116.152 7.02714C115.944 7.20234 115.804 7.44369 115.753 7.71053V7.71053ZM116.933 7.00843C117.022 7.00843 117.441 7.00843 117.364 7.39783C117.351 7.48271 117.313 7.56164 117.254 7.6239C117.195 7.68616 117.118 7.72874 117.034 7.74592V7.74592C117.223 7.79899 117.234 7.91696 117.205 8.09993C117.175 8.28279 117.169 8.37139 117.205 8.4304H116.91C116.91 8.38914 116.91 8.34185 116.91 8.16485C116.91 7.98779 116.91 7.87578 116.709 7.85807H116.455L116.349 8.44802H116.066L116.331 7.0202L116.933 7.00843ZM116.497 7.63384H116.745C116.845 7.63384 117.022 7.63387 117.063 7.40967C117.104 7.18545 116.975 7.24444 116.851 7.24444H116.562L116.497 7.63384Z" />
    </svg>
  );
}

export default OverdriveIconSVG;
