/** @jsx jsx */
import { jsx } from '@emotion/react';

export function HooplaIconSVG({ color, ...props }) {
  return (
    <svg
      width="61"
      height="24"
      viewBox="0 0 61 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      // css={{ width: '100%', height: 'auto' }}
      {...props}
    >
      <path d="M2 18.5512V1.09141H5.19946V7.24654C5.19946 7.24654 6.90922 5.58937 9.08229 5.63158C12.2196 5.69252 13.0894 8.25208 13.0894 8.25208C13.0894 8.25208 14.9221 5.84488 18.1526 5.84488C21.3831 5.84488 23.1847 8.06925 23.1847 8.06925C23.1847 8.06925 24.8307 5.78725 28.0616 5.84488C31.4785 5.90582 33.2801 8.31302 33.2801 8.31302C33.2801 8.31302 35.1775 5.72581 38.4365 5.78393C41.8534 5.84488 43.6861 8.31302 43.6861 8.31302V1H46.9477V8.52632C46.9477 8.52632 48.4387 5.75346 51.8866 5.72299C54.655 5.69853 55.8316 7.36842 55.8316 7.36842V6.0277H59V18.3379H55.8316V17.0277C55.8316 17.0277 54.4959 18.6427 51.9488 18.6427C48.6561 18.6427 46.9477 15.7175 46.9477 15.7175V18.6427H43.6861V16.1136C43.6861 16.1136 42.0708 18.6427 38.4054 18.6427C36.8213 18.6427 35.5787 18.0332 35.5787 18.0332V23H32.3172V17.2105C32.3172 17.2105 30.6728 18.7506 28.0616 18.7036C24.6757 18.6427 23.1537 16.5097 23.1537 16.5097C23.1537 16.5097 21.4763 18.7036 18.0905 18.7036C15.0463 18.7036 13.3689 16.6316 13.3689 16.6316V18.5512H10.2005V11.9695C10.2005 11.9695 10.4478 8.47233 8.02616 8.52632C5.29264 8.58726 5.23052 11.7867 5.23052 11.7867L5.16839 18.5512H2ZM28.1392 15.8393C30.0006 15.8393 31.5406 14.4377 31.5406 12.3047C31.5406 10.1717 30.1117 8.71401 28.1392 8.73961C26.278 8.76377 24.7379 10.0803 24.7379 12.3047C24.7379 14.5291 26.2779 15.8393 28.1392 15.8393ZM38.5142 15.7784C40.3755 15.7784 41.9155 14.3767 41.9155 12.2438C41.9155 10.1108 40.4866 8.65307 38.5142 8.67867C36.6529 8.70282 35.1128 10.0194 35.1128 12.2438C35.1128 14.4681 36.6528 15.7784 38.5142 15.7784ZM52.5856 15.8089C54.4027 15.8089 55.9869 14.3767 55.9869 12.2438C55.9869 10.1108 54.6512 8.58726 52.5856 8.58726C50.5818 8.58726 49.1842 10.0194 49.1842 12.2438C49.1842 14.4681 50.7684 15.8089 52.5856 15.8089ZM18.1992 15.8698C20.0605 15.8698 21.5695 14.4986 21.5695 12.2742C21.5695 10.0499 20.0605 8.70914 18.1992 8.70914C16.3378 8.70914 14.8289 10.0499 14.8289 12.2742C14.8289 14.4986 16.3378 15.8698 18.1992 15.8698Z" />
    </svg>
  );
}

export default HooplaIconSVG;
